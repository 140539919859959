import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { uniqBy } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { Link } from 'react-scroll'

import { Props as SuiteProps } from './props'

export interface Props {
  suites: SuiteProps[]
}

export const Filters = memo(function Filters({ suites }: Props) {
  if (suites.length < 1) {
    return null
  }

  const [filtersFixed, setFiltersFixed] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      setFiltersFixed(window.scrollY > window.innerHeight)
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Container className={filtersFixed ? 'fixed' : undefined} dial={5} row wrap>
      {uniqBy(suites, 'title').map((item, index) => {
        const section = item.title
          ? item.title.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()
          : undefined

        return (
          <Filter
            activeClass="active"
            key={index}
            offset={-50}
            spy={true}
            smooth={true}
            to={`suite-${section}`}
          >
            {`${item.title}`}
          </Filter>
        )
      })}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight5};
  border-top: 1px solid ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 0.8vw 2.5vw;
  position: fixed;
  top: 72px;
  left: 0;
  z-index: 90;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Filter = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8vw;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-right: 5vw;
  transition: 0.3s ease-in-out;
  opacity: 0.5;

  &:last-of-type {
    margin-right: 0;
  }
  &.active,
  &:hover {
    opacity: 1;
  }
`
