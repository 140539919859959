import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Filters } from './filters'
import { SuiteItem } from './item'
import { Props as SuiteItemProps } from './props'

export interface Props {
  suites?: SuiteItemProps[]
  languageCode: string
}

export const SuitesList = memo(function SuitesList({
  suites,
  languageCode,
}: Props) {
  const startBg = 'light'
  return (
    <>
      {suites ? <Filters suites={suites} /> : null}

      <Container>
        {suites
          ? suites.map((item, index) => {
              const section = item.title
                ? item.title.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()
                : undefined
              return (
                <SuiteItem
                  key={index}
                  index={index}
                  id={`suite-${section}`}
                  {...item}
                  variant={index % 2 == 0 ? 'default' : 'inverted'}
                  bg={
                    startBg == 'light'
                      ? index % 2 == 0
                        ? 'light'
                        : 'dark'
                      : index % 2 == 0
                      ? 'dark'
                      : 'light'
                  }
                />
              )
            })
          : null}
      </Container>
    </>
  )
})

const Container = styled.section``
